import {FC} from 'react'
import {Link} from 'react-router-dom'
import Card from 'react-bootstrap/Card';
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'


const HomePage: FC = () => {



  const intl = useIntl()



  const Service1 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.BIRTH_CERTIFICATE' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.IMMIGRATION_SERVICE' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.BIRTH_CERTIFICATE_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service2 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.NATIONAL_ID_CARD' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.IMMIGRATION_SERVICE' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.NATIONAL_ID_CARD_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service3 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.RETURNS_FILING' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.TAX_SERVICE' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.RETURNS_FILING_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service4 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.PASSPORT_APPLICATION' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.IMMIGRATION_SERVICE' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.PASSPORT_APPLICATION_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service5 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.LAND_SEARCH' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.LAND_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.LAND_SEARCH_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service6 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.DRIVING_LICENSE' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.TRANSPORT_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.DRIVING_LICENSE_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service7 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.PSV_LICENSE' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.TRANSPORT_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.PSV_LICENSE_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service8 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.TAX_PIN_APPLICATION' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.TAX_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.TAX_PIN_APPLICATION_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service9 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.BUSINESS_PERMIT' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.BUSINESS_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.BUSINESS_PERMIT_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service10 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.BUSINESS_NAME_REGISTRATION' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.BUSINESS_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.BUSINESS_NAME_REGISTRATION_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service11 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.TAX_COMPLIANCE_APPLICATION' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.TAX_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.TAX_COMPLIANCE_APPLICATION_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  const Service12 = () => {
    return (
      <Card style={{ width: '18rem' }}>
        <Card.Body>
          <Card.Title>{intl.formatMessage({ id: 'DASHBOARD.MARRIAGE_CERTIFICATE_APPLICATION' })}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{intl.formatMessage({ id: 'DASHBOARD.MARRIAGE_SERVICES' })}</Card.Subtitle>
          <Card.Text>
            {intl.formatMessage({ id: 'DASHBOARD.MARRIAGE_CERTIFICATE_APPLICATION_DESCRIPTION' })}
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.REQUEST_SERVICE' })}</Card.Link>
        </Card.Body>
      </Card>
    );
  }
  
  return (
    <div className='d-flex flex-column flex-root'>
      <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
        {/* begin::Heading */}
        <div className='d-flex flex-row flex-grow flex-center mb-10'>
          <img alt='Slider' src={toAbsoluteUrl('/media/misc/government_services_sl_sm.jpeg')} className='h-400px' />
        </div>
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'DASHBOARD.WELCOME_MESSAGE' })}</h1>

          <div className='fw-bold fs-4'>
            {intl.formatMessage({ id: 'DASHBOARD.INTRO_MESSAGE' })}
          </div>
          <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({ id: 'DASHBOARD.SELECT_SERVICE_MESSAGE' })}</div>
        </div>
        {/* begin::Heading */}
        <div className='d-flex flex-row justify-content-between align-items-center column-gap-10 p-10'>
          <Service1 />
          <Service2 />
          <Service3 />
        </div>
        <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
          <Service4 />
          <Service5 />
          <Service6 />
        </div>
        <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
          <Service7 />
          <Service8 />
          <Service9 />
        </div>
        <div className='d-flex flex-row flex-center align-items-center column-gap-10 p-10'>
          <Service10 />
          <Service11 />
          <Service12 />
        </div>
        {/* begin::Link */}
        <Link to='/auth' className='btn btn-primary'>
          {intl.formatMessage({ id: 'DASHBOARD.LOGIN_OR_REGISTER' })}
        </Link>
        {/* end::Link */}
        <p className='mt-20'>{intl.formatMessage({ id: 'DASHBOARD.COPYRIGHT_MESSAGE' })}</p>
      </div>
    </div>
  );
};

export { HomePage };