/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const intl = useIntl()
  
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id: 'DASHBOARD.MY_SERVICE_REQUESTS'})}</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                {intl.formatMessage({id: 'DASHBOARD.MONTH'})}
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                {intl.formatMessage({id: 'DASHBOARD.WEEK'})}
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                {intl.formatMessage({id: 'DASHBOARD.DAY'})}
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'>{intl.formatMessage({id: 'DASHBOARD.SERVICE'})}</th>
                    <th className='p-0 min-w-140px'>{intl.formatMessage({id: 'DASHBOARD.DATE'})}</th>
                    <th className='p-0 min-w-110px'>{intl.formatMessage({id: 'DASHBOARD.STATUS'})}</th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.TAX_PIN_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TAX_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{intl.formatMessage({id: 'DASHBOARD.APPROVED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.PASSPORT_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 May, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BUSINESS_PERMIT'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.BUSINESS_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 April, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>{intl.formatMessage({id: 'DASHBOARD.SUCCESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.DRIVING_LICENSE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TRANSPORT'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 Aug, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>{intl.formatMessage({id: 'DASHBOARD.REJECTED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BIRTH_CERTIFICATE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>22 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'>{intl.formatMessage({id: 'DASHBOARD.SERVICE'})}</th>
                    <th className='p-0 min-w-140px'>{intl.formatMessage({id: 'DASHBOARD.DATE'})}</th>
                    <th className='p-0 min-w-110px'>{intl.formatMessage({id: 'DASHBOARD.STATUS'})}</th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.TAX_PIN_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TAX_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{intl.formatMessage({id: 'DASHBOARD.APPROVED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.PASSPORT_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 May, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BUSINESS_PERMIT'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.BUSINESS_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 April, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>{intl.formatMessage({id: 'DASHBOARD.SUCCESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.DRIVING_LICENSE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TRANSPORT'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 Aug, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>{intl.formatMessage({id: 'DASHBOARD.REJECTED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BIRTH_CERTIFICATE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>22 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'>{intl.formatMessage({id: 'DASHBOARD.SERVICE'})}</th>
                    <th className='p-0 min-w-140px'>{intl.formatMessage({id: 'DASHBOARD.DATE'})}</th>
                    <th className='p-0 min-w-110px'>{intl.formatMessage({id: 'DASHBOARD.STATUS'})}</th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.TAX_PIN_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TAX_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{intl.formatMessage({id: 'DASHBOARD.APPROVED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.PASSPORT_APPLICATION'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 May, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BUSINESS_PERMIT'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.BUSINESS_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>15 April, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>{intl.formatMessage({id: 'DASHBOARD.SUCCESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.DRIVING_LICENSE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.TRANSPORT'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>12 Aug, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>{intl.formatMessage({id: 'DASHBOARD.REJECTED'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {intl.formatMessage({id: 'DASHBOARD.BIRTH_CERTIFICATE'})}
                      </a>
                      <span className='text-muted fw-semibold d-block'>{intl.formatMessage({id: 'DASHBOARD.IMMIGRATION_SERVICES'})}</span>
                    </td>
                    <td className='text-end text-muted fw-semibold'>22 June, 2022</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>{intl.formatMessage({id: 'DASHBOARD.IN_PROGRESS'})}</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}


export {TablesWidget5}
