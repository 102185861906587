/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Route, Routes, Outlet} from 'react-router-dom'
import {ListingPage} from './components/ListingPage'
import {HomePage} from './components/HomePage'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useIntl } from 'react-intl';

function PortalNav() {
  const intl = useIntl();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">{intl.formatMessage({ id: 'DASHBOARD.WELCOME_MESSAGE' })}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">{intl.formatMessage({ id: 'DASHBOARD.HOME' })}</Nav.Link>
            <Nav.Link href="/overview">{intl.formatMessage({ id: 'DASHBOARD.SERVICES' })}</Nav.Link>
            <Nav.Link href="/auth">{intl.formatMessage({ id: 'DASHBOARD.LOGIN_OR_REGISTER' })}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const LandingLayout = () => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/1.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <div className='d-flex align-items-center flex-center flex-row flex-row-fluid p-10 pb-lg-20'>
          
          <a href='#'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-drc.png')} className='h-45px' />
          </a>
          
          
          <PortalNav />
        </div>
        
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-1000px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
     
    </div>
  )
}

const LandingPage = () => (
  <Routes>
    <Route element={<LandingLayout />}>
      <Route path='home' element={<HomePage />} />
      <Route path='listing' element={<ListingPage />} />
      <Route index element={<HomePage />} />
    </Route>
  </Routes>
)

export {LandingPage}
