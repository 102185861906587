/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
 
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({id: 'MENU.SERVICES'})}</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/services/pages'
        title={intl.formatMessage({id: 'MENU.IMMIGRATION'})}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      > 
          <AsideMenuItem to='/services/pages/immigration/birth_certificates' title={intl.formatMessage({id: 'MENU.BIRTH_CERTIFICATES'})} hasBullet={true} />
          <AsideMenuItem to='/services/pages/immigration/national_id_cards' title={intl.formatMessage({id: 'MENU.NATIONAL_ID_CARD'})} hasBullet={true} />
          <AsideMenuItem to='/services/pages/immigration/passports' title={intl.formatMessage({id: 'MENU.PASSPORTS'})} hasBullet={true} />
          <AsideMenuItem
            to='/services/pages/immigration/resident_visas'
            title={intl.formatMessage({id: 'MENU.RESIDENT_VISAS'})}
            hasBullet={true}
          />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/services/transport'
        title={intl.formatMessage({id: 'MENU.TRANSPORT'})}
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/services/account/overview' title={intl.formatMessage({id: 'MENU.DRIVING_LICENSES'})} hasBullet={true} />
        <AsideMenuItem to='/services/account/settings' title={intl.formatMessage({id: 'MENU.INSPECTION_CERTIFICATES'})} hasBullet={true} />
        <AsideMenuItem to='/services/account/settings' title={intl.formatMessage({id: 'MENU.CAR_LOG_BOOKS'})} hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title={intl.formatMessage({id: 'MENU.TAX_SERVICES'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/pin_application' title={intl.formatMessage({id: 'MENU.PIN_APPLICATION'})} hasBullet={true} />
        <AsideMenuItem to='/services/returns_filing' title={intl.formatMessage({id: 'MENU.RETURNS_FILING'})} hasBullet={true} />
        <AsideMenuItem to='/services/tcc' title={intl.formatMessage({id: 'MENU.TAX_COMPLIANCE_CERTIFICATES'})} hasBullet={true} />
       
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title={intl.formatMessage({id: 'MENU.BUSINESS_SERVICES'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/business_permits' title={intl.formatMessage({id: 'MENU.BUSINESS_PERMITS'})} hasBullet={true} />
        <AsideMenuItem to='/services/business_name_registration' title={intl.formatMessage({id: 'MENU.BUSINESS_NAME_REGISTRATIONS'})} hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title={intl.formatMessage({id: 'MENU.LAND_SERVICES'})}
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/services/land_search' title={intl.formatMessage({id: 'MENU.LAND_SEARCH'})} hasBullet={true} />
        <AsideMenuItem to='/services/land_rent' title={intl.formatMessage({id: 'MENU.LAND_RENT'})} hasBullet={true} />
      </AsideMenuItemWithSub>
    </>
  )
}